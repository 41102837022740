import React from 'react';
import logo from './assest/logo.png';
import fb_icon from "./assest/facebook.svg";
import ig_icon from "./assest/instagram.svg";
import li_icon from "./assest/linkedin.svg";
import yt_icon from "./assest/youtube.svg";
import './App.css';


function App() {
  return (
    <div className="main-container">
      <img src={logo} className="logo" alt='skill surf logo' />
      <div>
        <p className='tagline-1'>Future is Yours to Shape!</p>
        <p className='tagline-2'>We Are Launching Soon. Stay Tuned!</p>
      </div>

      <div className='icon-row'>
        <a  target={"_blank"} rel="noopener noreferrer" href='https://www.facebook.com/skillsurfcodingcampus'><img src={fb_icon}
          alt="fb" /></a>
        <a target={"_blank"} rel="noopener noreferrer"  href='https://www.instagram.com/skillsurfcodingcampus/'><img src={ig_icon}
          alt="ig" /></a>
        <a  target={"_blank"} rel="noopener noreferrer" href='https://www.linkedin.com/company/skillsurfcodingcampus/'><img src={li_icon}
          alt="li" /></a>
        <a  target={"_blank"} rel="noopener noreferrer" href='https://www.youtube.com/@skillsurfcodingcampus'><img src={yt_icon}
          alt="yta" /></a>
      </div>

      <div className='footer'>
      <hr/>
        <p className='copyright-text'>© 2023 Skill Surf Coding Campus </p>
      </div>
    </div>
  );
}

export default App;
